import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from '@mui/material';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import truncate from 'lodash/truncate';

import {
  Heading,
  NamedLink,
  InlineTextButton,
} from '../../components';
import { richText } from '../../util/richText';
import {
  mapCategoryLabels,
  getCategoryFromSubcategory,
} from '../../util/types';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const TEXT_COLLAPSED_LENGTH = 600;

const truncated = s => {
  return truncate(s, {
    length: TEXT_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const formatWrapperID = (heading) => {
  if (!heading) {
    return '';
  }
  // remove special characters and spaces and make it lowercase
  return heading.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};


const SectionTextMaybe = props => {
  const {
    text: original_text,
    heading,
    showAsIngress = false,
    isCollapsible = false,
    publicData = null,
    isTruncated = false,
    elementId = null,
  } = props;
  const [expanded, setExpanded] = useState(isTruncated ? false : true);
  const textClass = showAsIngress ? css.ingress : css.text;

  const text = expanded ? original_text : truncated(original_text);

  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const {
    category = [],
    experience = [],
    subcategory = [],
    light_requirements = [],
    plant_light_requirements = [],
    water_requirements = [],
  } = publicData || {};
  
  const chip_labels = [];
  if (Array.isArray(category)) {
    category.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: mapCategoryLabels(c),
          page: 'CategoryPage',
          params: { pub_category: c },
        });
      }
    });
  }
  if (Array.isArray(subcategory)) {
    subcategory.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: mapCategoryLabels(c),
          page: 'SubcategoryPage',
          params: {
            pub_subcategory: c,
            pub_category: getCategoryFromSubcategory(c),
          },
        });
      }
    });
  }
  if (Array.isArray(light_requirements)) {
    light_requirements.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: `Light Requirements: ${mapCategoryLabels(c)}`,
        });
      }
    });
  }
  if (Array.isArray(plant_light_requirements)) {
    plant_light_requirements.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: `Light Requirements: ${mapCategoryLabels(c)}`,
        });
      }
    });
  }
  if (Array.isArray(water_requirements)) {
    water_requirements.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: `Water Requirements: ${mapCategoryLabels(c)}`,
        });
      }
    });
  }
  if (Array.isArray(experience)) {
    experience.forEach((c) => {
      if (c) {
        chip_labels.push({
          label: mapCategoryLabels(c),
          page: 'ExperiencePage',
          params: { pub_experience: c },
        });
      }
    });
  }

  const handleShowMoreClick = () => {
    setExpanded(true);
  };
  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      more
    </InlineTextButton>
  );

  if (!isCollapsible) {
    return text ? (
      <div id={elementId || `section-text-${formatWrapperID(heading)}`} className={css.sectionText}>
        {heading ? (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        ) : null}
        <p className={textClass}>
          {content}
          {original_text !== text && !expanded ? showMore : null}
        </p>
        {
          chip_labels.length > 0
          && (
            <div className={css.chipContainer}>
              {
                chip_labels.map((mi) => {
                  if (mi.page) {
                    return (
                      <NamedLink name={mi.page} params={mi.params}>
                        <Chip label={mi.label} className={css.chip} />
                      </NamedLink>
                    );
                  } else if (mi.label) {
                    return (<Chip label={mi.label} className={css.chip} />);
                  }
                })
              }
            </div>
          )
        }
      </div>
    ) : null;
  }

  return text ? (
    <div id={elementId || `section-text-${formatWrapperID(heading)}`} className={css.sectionText}>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
          }}
        >
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        </AccordionSummary>
        <AccordionDetails className={css.accordionDetails}>
          <p className={textClass}>
            {content}
            {original_text !== text && !expand ? showMore : null}
          </p>
          {
            chip_labels.length > 0
            && (
              <div className={css.chipContainer}>
                {
                  chip_labels.map(mi => (<Chip label={mi} className={css.chip} />))
                }
              </div>
            )
          }
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
};

export default SectionTextMaybe;
