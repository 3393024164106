import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);

  return existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
          }}
        >
          <Heading as="h2" rootClassName={css.sectionHeading}>
            <FormattedMessage id="ListingPage.detailsTitle" />
          </Heading>
        </AccordionSummary>
        <AccordionDetails
          className={css.accordionDetails}
        >
          <ul className={css.details}>
            {existingListingFields.map(detail => (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>{detail.label}</span>
                <span>{detail.value}</span>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
