import React from 'react';
import { Heading, PropertyGroup } from '../../components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const {
    heading,
    options,
    selectedOptions,
    isCollapsible = false,
  } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }

  if (!isCollapsible) {
    return (
      <div className={css.sectionMultiEnum}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
        <PropertyGroup
          id="ListingPage.amenities"
          options={options}
          selectedOptions={selectedOptions}
          twoColumns={options.length > 5}
        />
      </div>
    );
  }

  return (
    <div className={css.sectionMultiEnum}>
      <Accordion
        elevation={0}
        className={css.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
          }}
        >
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
            {
              selectedOptions?.length > 0 ? (
                <span className={css.accordionSelectedOptions}>
                  {selectedOptions.join(', ')}
                </span>
              ) : null
            }
          </Heading>
        </AccordionSummary>
        <AccordionDetails className={css.accordionDetails}>
          <PropertyGroup
            id="ListingPage.amenities"
            options={options}
            selectedOptions={selectedOptions}
            twoColumns={options.length > 5}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SectionMultiEnumMaybe;
