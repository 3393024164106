import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, PromiseButton } from '../../components';

import ListingReviewModal from './ListingReviewModal/ListingReviewModal';
import { eligbleToSubmitReviews } from '../../util/api';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const {
    reviews,
    fetchReviewsError,
    onManageDisableScrolling,
    onSubmitReview,
    listing,
    currentUser,
    submitListingReviewInProgress,
    submitListingReviewError,
    isOwnListing,
    onToggleReview,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  // console.log('listing', listing);

  // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  const showListingReviewModal = async () => {
    const results = await eligbleToSubmitReviews(listing?.id?.uuid);
    const is_eligible = results?.data?.is_eligible || false;
    const message = results?.data?.message || 'You are not allowed to leave a review';
    if (is_eligible) {
      setModalOpen(true);
    } else {
      // console.log('results', results, is_eligible);
      toast.error(message);
    }
  }

  const doSubmitReview = (values) => onSubmitReview(values).then(() => {
    setModalOpen(false);
  });

  const renderSubmitReviewModal = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <div className={css.submitReviewWrapper}>
        <ListingReviewModal
          isOpen={modalOpen}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={doSubmitReview}
          itemName={listing?.attributes?.title}
          onCloseModal={() => setModalOpen(false)}
          sendReviewInProgress={submitListingReviewInProgress}
          sendReviewError={submitListingReviewError}
        />
        <PromiseButton
          doPromise={() => showListingReviewModal()}
          className={css.submitReviewCTA}
        >
          Submit A Review
        </PromiseButton>
      </div>
    );
  };

  if (!currentUser && (!reviews || reviews.length <= 0)) {
    return null;
  }

  return (
    <div id="section-reviews" className={css.sectionReviews}>
      <Accordion elevation={0} className={css.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css.accordionSummary}
          classes={{
            content: css.accordionSummaryContent,
          }}
        >
          <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
            <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
          </Heading>
        </AccordionSummary>
        <AccordionDetails className={css.accordionDetails}>
          {renderSubmitReviewModal()}
          {fetchReviewsError ? (
            <H2 className={css.errorText}>
              <FormattedMessage id="ListingPage.reviewsError" />
            </H2>
          ) : null}
          <Reviews reviews={reviews} isOwnListing={isOwnListing} onToggleReview={onToggleReview} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const { bool, string, object, array, func } = PropTypes;

SectionReviews.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  reviews: [],
  fetchReviewsError: false,
  currentUser: null,
  submitListingReviewInProgress: false,
  submitListingReviewError: null,
  isOwnListing: false,
};

SectionReviews.propTypes = {
  className: string,
  rootClassName: string,
  // intl: intlShape.isRequired,
  listing: object,
  reviews: array,
  fetchReviewsError: bool,
  onManageDisableScrolling: func.isRequired,
  onSubmitReview: func.isRequired,
  currentUser: object,
  submitListingReviewInProgress: bool,
  submitListingReviewError: object,
  isOwnListing: bool,
  onToggleReview: func.isRequired,
};

export default SectionReviews;
